import React  from 'react';
import './tjenesteoversikt.css';

const TjenesteOversikt = () => {
  
return (
<div className='main-container'>
            <div className='inner-container'>
                <h2>Vi hjelper deg med det meste.</h2>
                <div className='grid-3-columns'>
                    <div className='grid-item'>
                        <h3 className='gradient'>Skyservere.</h3>
                        <p>
                            Vi kan erstatte dine lokale servere med skyservere. Lave, forutsigbare kostnader vil med dette erstatte
                            jevnlige investeringer og egen drift av hardware. Du får en trygg løsning, lagret på norsk datasenter,
                            som alltid er tilgjengelig fra hvor som helst.
                        </p>
                    </div>
                    <div className='grid-item'>
                        <h3 className='gradient'>Skylagring.</h3>
                        <p>
                            Vi kan tilby deg en norsk sikker skylagring, utviklet for bedrifter.
                            Ta del i alle fordelene en skyløsning gir og behold funksjonaliteten fra en lokal filserver.
                        </p>
                    </div>
                    <div className='grid-item'>
                        <h3 className='gradient'>Backup</h3>
                        <p>
                            Vi kan hjelpe deg å sikre dine data mot ransomware, maskinvarefeil, brann og menneskelig svikt.
                            Ta i bruk markedets beste Online Backup med lagring på norsk datasenter.
                            Svært enkel i bruk, samtidig som alt av sikkerhet er ivaretatt.
                        </p>
                    </div>
                    <div className='grid-item'>
                        <h3 className='gradient'>Datarekonstruksjon</h3>
                        <p>
                            Vi kan hente ut dine data fra datamaskin, mobil eller nettbrett når uhellet har vært ute,
                            og gi deg filene tilbake på minnepenn.
                        </p>
                    </div>
                    <div className='grid-item'>
                        <h3 className='gradient'>Digitalisering</h3>
                        <p>
                            Vi hjelper deg å få dine gamle filmklipp, for eksempel VHS, over til nyere format.
                        </p>
                    </div>
                    <div className='grid-item'>
                        <h3 className='gradient'>Kurs</h3>
                        <p>
                            Vi tilbyr kursing etter avtale i officepakken, dvs. Microsoft Word, Excel, Powerpoint og Outlook.
                        </p>
                    </div>

                </div>
                </div>
            </div>
      

  )
}


export default TjenesteOversikt;
