import React, { Component } from 'react'
import './header.css'

import {Link} from 'react-router-dom'

class Header extends Component {
    render() {
        return (
            <div className='header-container'>
                <div className='heading'>
                    <h1>{this.props.heading}</h1>
                    <p>{this.props.text}</p>
                    <Link to='/kontakt'><button className='btn'>Kontakt oss</button></Link>
                </div>
            </div>
        )
    }
}

export default Header
