import React, { Component } from 'react'
import './section.css'

import {Link} from 'react-router-dom'


class Section extends Component {
    render() {
        return (
            <div className='section-container light'>
                <div className='section'>
                    <h1>{this.props.heading}</h1>
                    <p>{this.props.text}</p>
                 </div>
            </div>
        )
    }
}

export default Section


