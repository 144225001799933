import React from 'react'
import { Footer, Navbar } from '../../components'
import Form from '../../components/form/Form'

const Contact = () => {
    return (
        <div>
            <Navbar />
            
            <div className='header-container'>
                <div className='heading'>
                    <h1>Vi fikser det meste.</h1>
                    <p>Enkelt sant?</p>'
                </div>
            </div>
            <Form />
            <Footer/>
        </div>
    )
}

export default Contact
