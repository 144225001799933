import { Footer, DarkSection, LightSection, Navbar, LeftNav, Header } from '../../components'
import React, { Component } from 'react'
import './tjenester.css'
import {Link} from 'react-router-dom'

const Tjenester = () => {
    return (
        <div>
            <Navbar />
            <LeftNav />
            <div className='header-container'>
                <div className='heading'>
                    <h1>Vi fikser det meste.</h1>
                    <p>Enkelt sant?</p>
                    <Link to='/kontakt'><button className='btn'>Kontakt oss</button></Link>
                </div>
            </div>
            <LightSection heading='Skylagring.' text='Vi fikser det meste.' />
            <DarkSection heading='Datarekonstruksjon.' text='Vi fikser det meste.' />
            <LightSection heading='Section.' text='Vi fikser det meste.' />
            <DarkSection heading='Section.' text='Vi fikser det meste.' />
            <LightSection heading='Section.' text='Vi fikser det meste.' />
            <DarkSection heading='Section.' text='Vi fikser det meste.' />
            <LightSection heading='Section.' text='Vi fikser det meste.' />
            <DarkSection heading='Section.' text='Vi fikser det meste.' />
            <LightSection heading='Section.' text='Vi fikser det meste.' />
            
            <Footer />
        </div>
    )
}

export default Tjenester
