import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {FaBars, FaTimes} from 'react-icons/fa';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/ITL_Logo_Hvit_SVG.svg';

const nav = document.querySelector(".navbar")

document.addEventListener("DOMContentLoaded", function() {
    var prev_scroll_pos = window.pageYOffset;
    window.onscroll = function () {
        var current_scroll_pos = window.pageYOffset;
        if (current_scroll_pos < nav.offsetHeight) {
            nav.style.background ="transparent"
        }
        else if (prev_scroll_pos > current_scroll_pos) {
            nav.style.top = "0";
            nav.style.background = "#03030399"
        }
        else {
            nav.style.top = "-" + nav.offsetHeight + "px";
            nav.style.background = "#03030399"
        }
        prev_scroll_pos = current_scroll_pos;
    };
})

const Navbar = () => {
  
  const[click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
  const [show, setShow] = useState(true)

  const [color, setColor] = useState(false)
      const changeColor =() => {
      if(window.scrollY >= 100) {
              setColor(true);

          } else {
              setColor(false)
          }
      }

      window.addEventListener('scroll', changeColor)

  return (
<div className='navbar-container'>
  <div className='navbar-links"'>
    <div className='navbar'>
      <div className={color ? 'navbar navbar-bg' : 'navbar'}>
        <ul className='nav-list'>
          <li className='nav-list-item'>
            <Link to='/'>Hjem</Link>
          </li>
          <li className='nav-list-item'>
            <Link to='/produkter'>Våre produkter</Link>
          </li>
          <li className='nav-list-item'>
            <Link to='/tjenester'>Våre tjenester</Link>
          </li>
          <li class="nav-list-logo">
            <Link to='/'><img src={logo} alt="Til hjemmesiden." class="logo"/></Link>
          </li>
          <li className='nav-list-item'>
            <Link to='/omoss'>Om oss</Link>
          </li>
          <li className='nav-list-item'>
            <Link to='/kontakt'>Kontakt oss</Link>
          </li>
          <li className='nav-list-item'>
            <Link to='http://itshop.no/'>Til nettbutikken</Link>
          </li>
        </ul>
        <div className='hamburger' onClick={handleClick}>
          {click ? (<FaTimes size={20} style={{color: '#fff'}} />) : (<FaBars size={20} style={{color: '#fff'}} />)}
             
         </div>
      </div>
  </div>
</div>
</div>
  )
}


export default Navbar
