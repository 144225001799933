import React from 'react'
import { Footer, Navbar, Form } from '../../components'

const Contact = () => {
    return (
        <div>
            <Navbar />
            
            <div className='header-container'>
                <div className='heading'>
                    <h1>Kontakt oss.</h1>
                    <p>Vi gleder oss til å høre fra deg.</p>
                </div>
            </div>
            <Form />
           npm 
        </div>
    )
}

export default Contact
