import React from 'react'
import { Link } from 'react-router-dom'
import './video.css'

import facebook from '../../assets/facebook.svg';
import twitter from '../../assets/twitter.svg';
import linkedin from '../../assets/linkedin.svg';
import instagram from '../../assets/instagram.svg';
import spaceVideo from '../../assets/banner-img_greyscale.jpg'

const Video = () => {

    return (
        <div className='hero'>
            <img src={spaceVideo} className='hero-img'/>
            <div className='content  fade-in from-bottom'>
                <h1>Vi fikser det.</h1>
                <p>Uansett behov finner vi IT-løsningen for deg. Enklere blir det ikke. </p>
                <div>
                    <Link to='/kontakt' className='btn btn-light'>Kontakt oss</Link>
                </div>
            </div>

            
            <div className='some-container'>
                            <Link to="https://www.facebook.com/itleveranse/" target="_blank" rel="noreferrer">
                                <img src={ facebook } alt="Facebook" className="some-icon"/>
                            </Link>
                            <Link to="https://twitter.com/itleveranseas" target="_blank" rel="noreferrer">
                                <img src={ twitter } alt="Twitter." className="some-icon"/>
                            </Link>
                            <Link to="https://no.linkedin.com/company/it-leveranse-as" target="_blank" rel="noreferrer">
                                <img src={ linkedin } alt="LinkedIn" className="some-icon"/>
                            </Link>
                            <Link to="https://www.instagram.com/itleveranse" target="_blank" rel="noreferrer">
                                <img src={ instagram } alt="Instagram" className="some-icon"/>
                            </Link>
                        </div>

        </div>
        
    )
}

export default Video




//import React from 'react'
//import { Link } from 'react-router-dom'
//import './video.css'

//import spaceVideo from '../../assets/video-placeholder.mp4'

//const Video = () => {
//    return (
//        <div className='hero'>
//            <video autoPlay loop muted id='video'>
//                <source src={spaceVideo} type='video/mp4' />
//            </video>
//            <div className='content'>
//                <h1>Vi fikser det.</h1>
//                <p>Uansett behov finner vi IT-løsningen for deg. Enklere blir det ikke. </p>
//                <div>
//                    <Link to='/kontakt' className='btn btn-light'>Kontakt oss</Link>
//                </div>
//            </div>
//        </div>
//    )
//}

//export default Video

