import React from 'react'
import {Routes, Route} from 'react-router-dom'
import { Hjem, Produkter, Tjenester, OmOss, Kontakt } from './pages'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Hjem />} />
        <Route path='/produkter' element={<Produkter />} />
        <Route path='/tjenester' element={<Tjenester />} />
        <Route path='/omoss' element={<OmOss />} />
        <Route path='/kontakt' element={<Kontakt />} />
      </Routes>
    </>
  );
}

export default App;
