import React from 'react'
import {Link} from 'react-router-dom'
import './reparasjon.css'

const Reparasjon = () => {
    return (
        <div className='reparasjon'>
            <h2>Vi reparerer når uhellet er ute.</h2>
            <div className='reparasjon-container'>
                <div className='reparasjon-content'>
                    <h3>- Datamaskin -</h3>
                    <span className='gradient-line'></span>
                    <p>Vi ordner opp når maskinen ikke tar lading, har oppstartsproblem, 
                        for lite diskplass, eller andre problemer. Vi tilbyr også service 
                        for å rense opp i filsystem og forbedre ytelse.
                    </p>
                </div>
                <div className='reparasjon-content'>
                    <h3>- Mobil -</h3>
                    <span className='gradient-line'></span>
                    <p>Vi reparerer de fleste mobilmerker, enten det gjelder knust skjerm, 
                        batteribytte eller programvarefeil. 
                    </p>
                </div>
                <div className='reparasjon-content'>
                    <h3>- Annet -</h3>
                    <span className='gradient-line'></span>
                    <p>Vi har reparert alt fra vaffeljern til stereoforsterkere og blålys. 
                        Vi liker å utfordre oss selv og sier sjeldent nei til et oppdrag.
                        </p>
                    </div>
            </div>
            <Link to='/contact' className='btn'>Book</Link>
                
        </div>
    )
}

export default Reparasjon;
