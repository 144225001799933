import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import './leftnav.css';

const LeftNav = () => {
  
return (
<div className='leftnav-container'>
    <div className='leftnavbar'>
        <ul className='leftnav-list'>
          <li className='leftnav-list-item'>
            <Link to='/'>Hjem</Link>
          </li>
          <li className='leftnav-list-item'>
            <Link to='/produkter'>Våre produkter</Link>
          </li>
          <li className='leftnav-list-item'>
            <Link to='/tjenester'>Våre tjenester</Link>
          </li>
          <li className='leftnav-list-item'>
            <Link to='/omoss'>Om oss</Link>
          </li>
          <li className='leftnav-list-item'>
            <Link to='/kontakt'>Kontakt oss</Link>
          </li>
          <li className='leftnav-list-item'>
            <Link to='http://itshop.no/'>Til nettbutikken</Link>
          </li>
        </ul>
  </div>
</div>

  )
}


export default LeftNav
