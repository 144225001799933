import React from 'react'
import { Footer, LightSection, DarkSection, Navbar, Header, LeftNav } from '../../components'
import './produkter.css'
import {Link} from 'react-router-dom'

const Produkter = () => {
    return (
        <div>
            <Navbar />
            <LeftNav />
            <div className='header-container'>
                <div className='heading'>
                    <h1>Vi fikser det meste.</h1>
                    <p>Enkelt sant?</p>
                    <Link to='/kontakt'><button className='btn'>Kontakt oss</button></Link>
                </div>
            </div>
            <DarkSection heading='Skylagring.' text='Vi fikser det meste.' />
            <LightSection heading='Datarekonstruksjon.' text='Vi fikser det meste.' />
            <DarkSection heading='Section.' text='Vi fikser det meste.' />
            <LightSection heading='Section.' text='Vi fikser det meste.' />
            <DarkSection heading='Section.' text='Vi fikser det meste.' />
            <LightSection heading='Section.' text='Vi fikser det meste.' />
            <DarkSection heading='Section.' text='Vi fikser det meste.' />
            <LightSection heading='Section.' text='Vi fikser det meste.' />
            <DarkSection heading='Section.' text='Vi fikser det meste.' />
            
            <Footer />
        </div>
    )
}

export default Produkter
