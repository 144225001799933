import React from 'react'
import {Link} from 'react-router-dom';

import './footer.css'

import facebook from '../../assets/facebook.svg';
import twitter from '../../assets/twitter.svg';
import linkedin from '../../assets/linkedin.svg';
import instagram from '../../assets/instagram.svg';

const Footer = () => {
    return (
        <footer className='grid-container-footer'>
            <div className='grid-footer'>
                <div className='grid-item-footer'>
                    <h3 className='gradient'>Finn oss i butikk</h3>
                    <span className='grid-item-footer'>
                        IT Leveranse <br></br>
                        Kaigata 6 <br></br>
                        6390 Vestnes <br></br>
                        Møre og Romsdal <br></br>
                        Norge <br></br>
                        <br></br>
                        <a href="https://www.google.com/maps/place/IT+Leveranse+AS/@62.623231,7.090907,15z/data=!4m5!3m4!1s0x0:0x30d2c328c7c305f!8m2!3d62.623231!4d7.090907" target="_blank" rel="noreferrer">Klikk for å se i kart.</a>
                    </span>
                </div>
                <div class="grid-item-footer">
                        <h3 className='gradient'>Finn oss på nett.</h3>
                        <div class="vertical-some-container">
                            <Link to="https://www.facebook.com/itleveranse/" target="_blank" rel="noreferrer">
                                <img src={ facebook } alt="Facebook" className="some-icon"/>
                            </Link>
                            <Link to="https://twitter.com/itleveranseas" target="_blank" rel="noreferrer">
                                <img src={ twitter } alt="Twitter." className="some-icon"/>
                            </Link>
                            <Link to="https://no.linkedin.com/company/it-leveranse-as" target="_blank" rel="noreferrer">
                                <img src={ linkedin } alt="LinkedIn" className="some-icon"/>
                            </Link>
                            <Link to="https://www.instagram.com/itleveranse" target="_blank" rel="noreferrer">
                                <img src={ instagram } alt="Instagram" className="some-icon"/>
                            </Link>
                        </div>
                </div>
                <div class="grid-item-footer">
                        <h3 className='gradient'>Kom i kontakt.</h3>
                        <span>
                            + 47 717 00 170 <br></br>
                            post@itleveranse.no <br></br>
                        </span>
                </div>
            </div>
               

            <div class="copyright">Copyright @2022</div>
               
        </footer>
    )
}

export default Footer
