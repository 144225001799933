import React, { Component } from 'react'
import './section.css'

class Section extends Component {
    render() {
        return (
            <div className='section-container dark'>
                <div className='section'>
                    <h1>{this.props.heading}</h1>
                    <p>{this.props.text}</p>
                 </div>
            </div>
        )
    }
}

export default Section


